import React, { useEffect, useState } from "react";
import CalendarHeatmap from "react-calendar-heatmap";
import { Tooltip } from 'react-tooltip';

import { getRange, shiftDate } from './utils';
import {
  ContributionGraphContainer,
  ContributionGraphHeader,
  ContributionGraphProgress
} from "./StyledComponents";
import { CONTRIBUTION_GRAPH_TOOLTIP_ID } from './constants';
import { fetchStreaks, upsertStreaks } from './databaseService';
import ContributionGraphLabel from "./ContributionGraphLabel";

const ContributionGraph = ({
  id,
  label,
  startDate: propStartDate,
  endDate: propEndDate,
  initialValues: propInitialValues,
  classForValue: propClassForValue,
  tooltipDataAttrs: propTooltipDataAttrs,
  onClick: propOnClick,
  progress: propProgress,
  updateContributionGraph
}) => {
  // Style changes: Push weekdays away from the contribution graph!
  const weekdayLabels = document.getElementsByClassName('react-calendar-heatmap-weekday-labels');
  for (var elem of weekdayLabels) {
    elem.setAttribute('transform', 'translate(6, 14)');
  }

  const today = new Date();
  const startDate = propStartDate || shiftDate(today, -366);
  const endDate = propEndDate || new Date();
  endDate.setDate(endDate.getDate() + 1);

  // Important! We need to set time portion as 0 otherwise we will get different epochs for same date
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  const initialValues = propInitialValues || getRange(366).map((index) => {
    const date = shiftDate(endDate, -index);
    return {
      epoch: date.getTime(),
      date,
      count: 0
    };
  });

  const [values, setValues] = useState(initialValues);
  const [subs, setSubs] = useState([]);

  useEffect(() => {
    (async () => {
      const sub = await fetchStreaks(id, initialValues, setValues);
      setSubs([...subs, sub]);
    })();

    return () => {
      subs.forEach((sub) => sub.unsubscribe());
    };
  }, []);

  const classForValue = propClassForValue || ((value) => {
    if (!value || value.count === 0) {
      return "color-empty";
    }
    return `color-github-${value.count}`;
  });

  const tooltipDataAttrs = propTooltipDataAttrs || ((value) => {
    if (!value || !value.date) {
      return null;
    }
    return {
      'data-tooltip-id': CONTRIBUTION_GRAPH_TOOLTIP_ID,
      'data-tooltip-content': `Date: ${value.date.toISOString().slice(0, 10)}`
    };
  });

  const onClick = propOnClick || (async (value) => {
    const newValues = await upsertStreaks(id, values, value);
    setValues(newValues);
  });

  const progress = propProgress || '';

  return (
    <ContributionGraphContainer>
      <ContributionGraphHeader>
        <ContributionGraphLabel 
          graphId={id} 
          initialLabel={label} 
          updateContributionGraph={updateContributionGraph} 
        />
        <ContributionGraphProgress>
          {progress}
        </ContributionGraphProgress>
      </ContributionGraphHeader>
      <CalendarHeatmap
        startDate={startDate}
        endDate={endDate}
        values={values}
        classForValue={classForValue}
        tooltipDataAttrs={tooltipDataAttrs}
        showWeekdayLabels={true}
        onClick={onClick}
      />
      <Tooltip id={CONTRIBUTION_GRAPH_TOOLTIP_ID} />
    </ContributionGraphContainer>
  );
};

export default ContributionGraph;
