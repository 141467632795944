import * as Database from '../Database';

export const fetchActivityLogs = async (currentActivityLogId, setRows) => {
    try {
        const db = await Database.get();
        const sub = db.activity_logs.findOne({
            selector: {
                id: currentActivityLogId
            }
        }).$.subscribe((activityLogsDocument) => {
            if (!activityLogsDocument) {
                setRows([]);
            } else {
                setRows(activityLogsDocument.activityLogs);
            }
        });
        return sub;
    } catch (error) {
        console.error('Error fetch activity logs', error);
    }
};

export const upsertActivityLogs = async (currentActivityLogId, data) => {
    const db = await Database.get();
    const activityLogsCollecion = db.activity_logs;
    const activityLogsDocument = await activityLogsCollecion
        .findOne({
            selector: {
                id: currentActivityLogId
            }
        }).exec();
    if (!activityLogsDocument) {
        await activityLogsCollecion.insert({
            id: currentActivityLogId,
            activityLogs: [data.log]
        });
    } else {
        await activityLogsDocument.modify((doc) => {
            doc.activityLogs = [...doc.activityLogs, data.log];
            return doc;
        });
    }
};
