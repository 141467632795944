import styled from 'styled-components';
import { Typography } from "@mui/material";

export const ContributionGraphsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DraggableContributionGraphContainer = styled.div`
    opacity: ${props => props.$isdragging ? 0 : 1};
    width: 950px;
    height: 200px;
    display: flex;
    margin: auto;
    padding-left: 20px;
    margin-top: 20px;
`;

export const ContributionGraphContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 950px;
    height: 300px;
`;

export const ContributionGraphHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const StyledContributionGraphLabel = styled.input`
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    border: none; /* Remove borders */
    outline: none; /* Remove focus outline */
    cursor: text; /* Change cursor to indicate text can be edited */
    color: '#D72525';
    background-color: 'transparent';
`;

export const ContributionGraphProgress = styled(Typography)`
    color: #A2A2A2;
`;

export const ContributionGraphActionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
`;
