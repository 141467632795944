import * as Database from '../Database';

export const fetchTimeTables = async (day, callback) => {
    try {
        const db = await Database.get();
        const query = db.timetable.findOne({
            selector: {
                day
            }
        });

        const timeTableDocument = await query.exec();
        callback(timeTableDocument);

        const sub = query.$.subscribe((timeTableDocument) => {
            if (timeTableDocument) {
                callback(timeTableDocument);
            }
        });

        return sub;
    } catch (error) {
        console.error('Error fetch time table', error);
    }
};

export const updateTimeTable = async (id, timeTableItems) => {
    try {
        const db = await Database.get();
        const timeTableCollection = db.timetable;
        const timeTableDocument = await timeTableCollection.findOne({
            selector: {
                id
            }
        }).exec();
        if (timeTableDocument) {
            await timeTableDocument.incrementalModify((doc) => {
                doc.timeTableItems = timeTableItems;
                return doc;
            });
        } else {
            console.error('No time table found by id =', id);
        }
    } catch (error) {
        console.error('Error fetch time table', error);
    }
};

export const upsertTimeTableItem = async (
    timeTableId,
    id,
    startTime,
    endTime,
    icon,
    title,
    description,
    showTime
) => {
    try {
        const db = await Database.get();
        const timeTableCollection = db.timetable;

        const timeTableDocument = await timeTableCollection.findOne({
            selector: {
                id: timeTableId
            }
        }).exec();

        if (timeTableDocument) {
            const timeTableItems = timeTableDocument.timeTableItems;
            const itemIndex = timeTableItems.findIndex(item => item.id === id);
            if (itemIndex !== -1) {
                const updatedItem = { ...timeTableItems[itemIndex], startTime, endTime, icon, title, description, showTime };
                const updatedTimeTableItems = [
                    ...timeTableItems.slice(0, itemIndex),
                    updatedItem,
                    ...timeTableItems.slice(itemIndex + 1)
                ];
                await timeTableDocument.incrementalModify((doc) => {
                    doc.timeTableItems = updatedTimeTableItems;
                    return doc;
                });
            } else {
                const timeTableItems = timeTableDocument.timeTableItems;
                const newItem = {
                    id,
                    index: timeTableItems.length,
                    startTime,
                    endTime,
                    icon,
                    title,
                    description,
                    showTime
                };
                const updatedTimeTableItems = timeTableItems.concat(newItem);
                await timeTableDocument.incrementalModify((doc) => {
                    doc.timeTableItems = updatedTimeTableItems;
                    return doc;
                });
            }
        } else {
            console.error('No time table found by id =', timeTableId);
        }
    } catch (error) {
        console.error('Error fetch time table', error);
    }
};

export const removeTimeTableItem = async (timeTableId, id) => {
    try {
        const db = await Database.get();
        const timeTableCollection = db.timetable;

        const timeTableDocument = await timeTableCollection.findOne({
            selector: {
                id: timeTableId
            }
        }).exec();
        if (timeTableDocument) {
            const timeTableItems = timeTableDocument.timeTableItems;
            const itemIndex = timeTableItems.findIndex(item => item.id === id);
            if (itemIndex !== -1) {
                const updatedTimeTableItems = timeTableItems.filter(item => item.id !== id);
                await timeTableDocument.incrementalModify((doc) => {
                    doc.timeTableItems = updatedTimeTableItems;
                    return doc;
                });
            }
        } else {
            console.error('No time table found by id =', timeTableId);
        }
    } catch (error) {
        console.error('Error fetch time table', error);
    }
};
