import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    style: {},
    currentActivityLogId: uuidv4(),
    inProgress: false
};

export const seesawStopWatchSlice = createSlice({
    name: 'seesawStopWatch',
    initialState,
    reducers: {
        updateStyle: (state, action) => {
            state.style = action.payload;
        },
        createNewActivityLogId: (state) => {
            state.currentActivityLogId = uuidv4();
        },
        setInProgress: (state, action) => {
            state.inProgress = action.payload;
        },
    }
});

export const {
    updateStyle,
    createNewActivityLogId,
    setInProgress
} = seesawStopWatchSlice.actions;

export const selectStyle = (state) => state.seesawStopWatch.style;
export const selectCurrentActivityLogId = (state) => state.seesawStopWatch.currentActivityLogId;
export const selectSeesawStopwatchInProgress = (state) => state.seesawStopWatch.inProgress;

export default seesawStopWatchSlice.reducer;
