import React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';

const vertical = 'top';
const horizontal = 'center';

const VisibilitySnackbar = ({ state, onClose }) => {
    const { open, severity, message } = state;
    return <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={onClose}
        autoHideDuration={3000}
        TransitionComponent={Fade}
    >
        <Alert
            severity={severity}
            variant="filled"
            sx={{ width: '100%' }}
        >
            {message}
        </Alert>
    </Snackbar>;
};

export default VisibilitySnackbar;
