import React, { useState, useEffect } from 'react';
import { HexColorPicker } from "react-colorful";
import { useSelector, useDispatch } from 'react-redux';

import {
  ResponsiveSettingsButton,
  SettingsModal,
  SettingsModalHeader,
  SettingsModalButtonsContainer,
  SettingsModalButton,
} from './StyledComponents';
import { selectStyle, updateStyle } from '../seesawStopWatchSlice';

const Settings = () => {
  const [showButton, setShowButton] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [themeColor, setThemeColor] = useState("#37352F");

  const style = useSelector(selectStyle);
  const dispatch = useDispatch();

  const toggleSettingsModal = () => {
    setShowModal(!showModal);
  };

  const toggleSettingsButton = (isVisible) => {
    setShowButton(isVisible);
  };

  const loadPreferences = () => {
    var savedThemeColor = localStorage.getItem('themeColor');
    if (savedThemeColor) {
      dispatch(updateStyle({ themeColor: savedThemeColor }));
    }
  }

  const savePreferences = (themeColor) => {
    localStorage.setItem('themeColor', themeColor);
  };

  const handleSaveSettings = () => {
    var selectedFontColor = themeColor;
    dispatch(updateStyle({ themeColor: selectedFontColor }));
    toggleSettingsModal();
    savePreferences(selectedFontColor);
  };

  const handleCancelSettings = () => {
    toggleSettingsModal();
  };

  useEffect(() => {
    let timeout;

    const handleMouseMove = () => {
      clearTimeout(timeout);
      toggleSettingsButton(true);
      timeout = setTimeout(() => toggleSettingsButton(false), 2000);
    };

    document.addEventListener('mousemove', handleMouseMove);

    loadPreferences();

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <>
      {/* 
        Warning: Received `true` for a non-boolean attribute `show`
        https://github.com/styled-components/styled-components/issues/1198#issuecomment-336628848 
        https://github.com/styled-components/styled-components/issues/1198#issuecomment-696306096
      */}
      <ResponsiveSettingsButton $show={showButton} style={style} onClick={toggleSettingsModal}>
        Settings
      </ResponsiveSettingsButton>
      {showModal && (
        <>
          <div 
            className="modal-background"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backdropFilter: 'blur(6px)', 
              zIndex: 999 /* Ensure it's above other elements */
            }}
          ></div>
          <SettingsModal $show={showModal}>
            <SettingsModalHeader>Choose Seesaw Stopwatch Theme</SettingsModalHeader>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '30px' }}>
              <HexColorPicker color={themeColor} onChange={setThemeColor} />
            </div>
            <SettingsModalButtonsContainer>
              <SettingsModalButton
                id="save-settings"
                style={style}
                onClick={handleSaveSettings}
              >
                Save
              </SettingsModalButton>
              <SettingsModalButton
                id="cancel-settings"
                style={style}
                onClick={handleCancelSettings}
              >
                Cancel
              </SettingsModalButton>
            </SettingsModalButtonsContainer>
          </SettingsModal>
        </>
      )}

    </>
  );
}

export default Settings;
