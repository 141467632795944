import { createRxDatabase, addRxPlugin } from 'rxdb';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { getFetchWithCouchDBAuthorization, replicateCouchDB } from 'rxdb/plugins/replication-couchdb';
import { v4 as uuidv4 } from 'uuid';

import { activityLogsSchema, contributionGraphSchema, timeTableSchema } from './Schema';
import { getLoggedInUser } from './utils';

addRxPlugin(RxDBLeaderElectionPlugin);

let dbPromise = null;

const _create = async (userId) => {
    let syncURL;
    if (process.env.NODE_ENV === "development") {
        syncURL = `http://${window.location.hostname}:${process.env.REACT_APP_COUCHDB_PORT}/`;
    } else {
        syncURL = `https://${window.location.hostname}:${process.env.REACT_APP_COUCHDB_PORT}/`;
    }
    console.log('host: ' + syncURL);
    
    if (process.env.NODE_ENV === "development") {
        await import('rxdb/plugins/dev-mode').then(
            module => addRxPlugin(module.RxDBDevModePlugin)
        );
    } else {
        // TODO: Enabling dev mode for production is not recommended. Remove this in production.
        await import('rxdb/plugins/dev-mode').then(
            module => addRxPlugin(module.RxDBDevModePlugin)
        );
    }

    console.log('DatabaseService: creating database..');
    const db = await createRxDatabase({
        name: `visibilitydb_${userId}`,
        storage: getRxStorageDexie()
    });
    console.log('DatabaseService: created database');
    window['db'] = db; // write to window for debugging

    // Show leadership in title
    db.waitForLeadership().then(() => {
        console.log('isLeader now');
    });


    // Create collections
    console.log('DatabaseService: create collections');
    await db.addCollections({
        activity_logs: {
            schema: activityLogsSchema,
            methods: {
                hpPercent() {
                    return this.hp / this.maxHP * 100;
                }
            }
        },
        contribution_graph: {
            schema: contributionGraphSchema,
            methods: {
                hpPercent() {
                    return this.hp / this.maxHP * 100;
                }
            }
        },
        timetable: {
            schema: timeTableSchema,
            methods: {
                hpPercent() {
                    return this.hp / this.maxHP * 100;
                }
            }
        }
    });


    // Sync
    console.log('DatabaseService: sync');
    await Promise.all(
        Object.values(db.collections).map(async (col) => {
            try {
                // create the CouchDB database
                await fetch(
                    `${syncURL}${col.name}_${userId}/`,
                    {
                        method: 'PUT',
                        headers: {
                            Authorization: `Basic ${btoa(`${process.env.REACT_APP_COUCHDB_USERNAME}:${process.env.REACT_APP_COUCHDB_PASSWORD}`)}`
                        }
                    },
                ).then(response => {
                    if (!response.ok) {
                        throw new Error(`Failed to create database: ${response.status}`);
                    }
                    console.log(`Created database: ${col.name}_${userId}`);
                });

                // Init contribution graphs
                if (col.name === 'contribution_graph') {
                    const graphCollection = db.contribution_graph;
                    const graphDocuments = await graphCollection.find().exec();
                    if (graphDocuments.length === 0) {
                        await graphCollection.bulkInsert([
                            { id: uuidv4(), index: 1, activity: 'Study', streaks: [] },
                            { id: uuidv4(), index: 2, activity: 'Gym', streaks: [] },
                            { id: uuidv4(), index: 3, activity: 'Reading', streaks: [] },
                            { id: uuidv4(), index: 4, activity: 'Meditation', streaks: [] },
                            { id: uuidv4(), index: 5, activity: 'Fasting', streaks: [] }
                        ]);
                    };

                    // Year progress document is maintained in the database only to capture its place in the ordering of graphs.
                    // So soon as new year starts, last year's document looses relevance and is removed!
                    const currentYear = new Date().getFullYear();
                    let index = 0;
                    const lastYearProgressDocument = await graphCollection.findOne({
                        selector: {
                            activity: (currentYear - 1).toString()
                        }
                    }).exec();
                    if (lastYearProgressDocument) {
                        index = lastYearProgressDocument.index;
                        lastYearProgressDocument.remove();
                    }
                    const yearProgressDocument = await graphCollection.findOne({
                        selector: {
                            activity: currentYear.toString()
                        }
                    }).exec();
                    if (!yearProgressDocument) {
                        await graphCollection.insert({
                            id: uuidv4(),
                            index, // Place new year progress at the same place in the order of graphs as last year progress
                            activity: currentYear.toString(),
                            streaks: []
                        });
                    }
                }

                // Init time tables
                if (col.name === 'timetable') {
                    const timeTableCollection = db.timetable;
                    const timeTableDocument = await timeTableCollection.find().exec();
                    if (timeTableDocument.length === 0) {
                        await timeTableCollection.bulkInsert([
                            {
                                id: uuidv4(),
                                day: 'wfh',
                                timeTableItems: [
                                    { id: uuidv4(), startTime: '04:30 am', endTime: '08:00 am', icon: 'local_library', title: 'Study', description: 'To get ahead of the 99% of the people, you\'ll have to do what the 99% are unwilling to do', showTime: true },
                                    { id: uuidv4(), startTime: '08:00 am', endTime: '08:30 am', icon: 'visibility', title: 'Eye Exercise', description: 'Give me six hours to chop down a tree and I will spend the first four sharpening the axe.', showTime: true },
                                    { id: uuidv4(), startTime: '08:30 am', endTime: '09:00 am', icon: 'pets', title: 'Walk Sascha', description: null, showTime: true },
                                    { id: uuidv4(), startTime: '09:00 am', endTime: '09:30 am', icon: 'fastfood', title: 'Breakfast', description: null, showTime: true },
                                    { id: uuidv4(), startTime: '09:30 am', endTime: '12:30 pm', icon: 'laptop_mac', title: 'Work', description: null, showTime: true },
                                    { id: uuidv4(), startTime: '12:30 pm', endTime: '01:30 pm', icon: 'restaurant', title: 'Lunch Break', description: 'Besides Lunch, Chores & Social Media', showTime: true },
                                    { id: uuidv4(), startTime: '01:30 pm', endTime: '03:00 pm', icon: 'headphones', title: 'Personal Break', description: 'Reading, Guitar or Drawing', showTime: true },
                                    { id: uuidv4(), startTime: '03:00 pm', endTime: '05:30 pm', icon: 'laptop_mac', title: 'Work', description: null, showTime: true },
                                    { id: uuidv4(), startTime: '05:30 pm', endTime: '07:00 pm', icon: 'fitness_center', title: 'Gym', description: 'The only bad workout is the one that didn’t happen', showTime: true },
                                    { id: uuidv4(), startTime: '07:00 pm', endTime: '09:30 pm', icon: 'restaurant', title: 'Personal Break', description: 'Dinner, Chores, Personal & Sascha Time', showTime: true },
                                    { id: uuidv4(), startTime: '09:30 pm', endTime: '04:30 am', icon: 'hotel', title: 'Meditation & Sleep', description: 'Because you need rest', showTime: true },
                                ]
                            },
                            {
                                id: uuidv4(),
                                day: 'wfo',
                                timeTableItems: [
                                    { id: uuidv4(), startTime: '04:30 am', endTime: '07:30 am', icon: 'local_library', title: 'Study', description: 'To get ahead of the 99% of the people, you\'ll have to do what the 99% are unwilling to do', showTime: true },
                                    { id: uuidv4(), startTime: '07:30 am', endTime: '08:00 am', icon: 'visibility', title: 'Eye Exercise', description: 'Give me six hours to chop down a tree and I will spend the first four sharpening the axe.', showTime: true },
                                    { id: uuidv4(), startTime: '08:00 am', endTime: '08:45 am', icon: 'pets', title: 'Walk Sascha & Breakfast', description: null, showTime: true },
                                    { id: uuidv4(), startTime: '08:45 am', endTime: '09:30 am', icon: 'laptop_mac', title: 'Travel to Work', description: null, showTime: true },
                                    { id: uuidv4(), startTime: '09:30 am', endTime: '05:30 pm', icon: 'laptop_mac', title: 'Work', description: null, showTime: true },
                                    { id: uuidv4(), startTime: '05:30 pm', endTime: '07:30 pm', icon: 'fitness_center', title: 'Gym & Travel back Home', description: 'The only bad workout is the one that didn’t happen', showTime: true },
                                    { id: uuidv4(), startTime: '07:30 pm', endTime: '09:30 pm', icon: 'restaurant', title: 'Personal Break', description: 'Dinner, Chores, Personal & Sascha Time', showTime: true },
                                    { id: uuidv4(), startTime: '09:30 pm', endTime: '04:30 am', icon: 'hotel', title: 'Meditation & Sleep', description: 'Because you need rest', showTime: true },
                                ]
                            },
                            {
                                id: uuidv4(),
                                day: 'saturday',
                                timeTableItems: [
                                    { id: uuidv4(), startTime: '04:30 am', endTime: '08:00 am', icon: 'local_library', title: 'Study', description: 'To get ahead of the 99% of the people, you\'ll have to do what the 99% are unwilling to do', showTime: true },
                                    { id: uuidv4(), startTime: '08:00 am', endTime: '08:30 am', icon: 'visibility', title: 'Eye Exercise', description: 'Give me six hours to chop down a tree and I will spend the first four sharpening the axe.', showTime: true },
                                    { id: uuidv4(), startTime: '08:30 am', endTime: '09:30 am', icon: 'pets', title: 'Walk Sascha & Breakfast', description: null, showTime: true },
                                    { id: uuidv4(), startTime: '09:30 am', endTime: '12:30 pm', icon: 'local_library', title: 'Study', description: null, showTime: true },
                                    { id: uuidv4(), startTime: '12:30 pm', endTime: '01:30 pm', icon: 'restaurant', title: 'Lunch Break', description: 'Besides Lunch, Chores & Social Media', showTime: true },
                                    { id: uuidv4(), startTime: '01:30 pm', endTime: '05:30 pm', icon: 'local_library', title: 'Study', description: null, showTime: true },
                                    { id: uuidv4(), startTime: '05:30 pm', endTime: '07:00 pm', icon: 'fitness_center', title: 'Gym', description: 'The only bad workout is the one that didn’t happen', showTime: true },
                                    { id: uuidv4(), startTime: '07:00 pm', endTime: '09:30 pm', icon: 'restaurant', title: 'Personal Break', description: 'Dinner, Chores, Personal & Sascha Time', showTime: true },
                                    { id: uuidv4(), startTime: '09:30 pm', endTime: '04:30 am', icon: 'hotel', title: 'Meditation & Sleep', description: 'Because you need rest', showTime: true },
                                ]
                            },
                            {
                                id: uuidv4(),
                                day: 'sunday',
                                timeTableItems: [
                                    { id: uuidv4(), startTime: '04:30 am', endTime: '08:00 am', icon: 'local_library', title: 'Study', description: 'To get ahead of the 99% of the people, you\'ll have to do what the 99% are unwilling to do', showTime: true },
                                    { id: uuidv4(), startTime: '08:00 am', endTime: '08:30 am', icon: 'visibility', title: 'Eye Exercise', description: 'Give me six hours to chop down a tree and I will spend the first four sharpening the axe.', showTime: true },
                                    { id: uuidv4(), startTime: '08:30 am', endTime: '09:30 am', icon: 'pets', title: 'Walk Sascha & Breakfast', description: null, showTime: true },
                                    { id: uuidv4(), startTime: '09:30 am', endTime: '12:30 pm', icon: 'local_library', title: 'Study', description: null, showTime: true },
                                    { id: uuidv4(), startTime: '12:30 pm', endTime: '01:30 pm', icon: 'restaurant', title: 'Lunch', description: null, showTime: true },
                                    { id: uuidv4(), startTime: '01:30 pm', endTime: '09:30 pm', icon: 'restaurant', title: 'Personal Break', description: 'Dinner, Chores, Personal & Sascha Time', showTime: true },
                                    { id: uuidv4(), startTime: '09:30 pm', endTime: '04:30 am', icon: 'hotel', title: 'Meditation & Sleep', description: 'Because you need rest', showTime: true },
                                ]
                            },
                            {
                                id: uuidv4(),
                                day: 'holiday',
                                timeTableItems: [
                                    { id: uuidv4(), startTime: '04:30 am', endTime: '08:00 am', icon: 'local_library', title: 'Study', description: 'To get ahead of the 99% of the people, you\'ll have to do what the 99% are unwilling to do', showTime: true },
                                    { id: uuidv4(), startTime: '08:00 am', endTime: '08:30 am', icon: 'visibility', title: 'Eye Exercise', description: 'Give me six hours to chop down a tree and I will spend the first four sharpening the axe.', showTime: true },
                                    { id: uuidv4(), startTime: '08:30 am', endTime: '09:30 am', icon: 'pets', title: 'Walk Sascha & Breakfast', description: null, showTime: true },
                                    { id: uuidv4(), startTime: '09:30 am', endTime: '12:30 pm', icon: 'local_library', title: 'Study', description: null, showTime: true },
                                    { id: uuidv4(), startTime: '12:30 pm', endTime: '01:30 pm', icon: 'restaurant', title: 'Lunch Break', description: 'Besides Lunch, Chores & Social Media', showTime: true },
                                    { id: uuidv4(), startTime: '01:30 pm', endTime: '05:30 pm', icon: 'local_library', title: 'Study', description: null, showTime: true },
                                    { id: uuidv4(), startTime: '05:30 pm', endTime: '07:00 pm', icon: 'fitness_center', title: 'Gym', description: 'The only bad workout is the one that didn’t happen', showTime: true },
                                    { id: uuidv4(), startTime: '07:00 pm', endTime: '09:30 pm', icon: 'restaurant', title: 'Personal Break', description: 'Dinner, Chores, Personal & Sascha Time', showTime: true },
                                    { id: uuidv4(), startTime: '09:30 pm', endTime: '04:30 am', icon: 'hotel', title: 'Meditation & Sleep', description: 'Because you need rest', showTime: true },
                                ]
                            },
                        ]);
                    };

                }
            } catch (err) {
                console.error(`Failed to create database for collection ${col.name}:`, err);
            }
        })
    );
    console.log('DatabaseService: sync - start live');
    Object.values(db.collections).map(async (col) => {
        const colName = col.name;
        const url = `${syncURL}${colName}_${userId}/`;
        console.log('url: ' + url);
        const replicationState = replicateCouchDB({
            collection: db[colName],
            url,
            fetch: getFetchWithCouchDBAuthorization(process.env.REACT_APP_COUCHDB_USERNAME, process.env.REACT_APP_COUCHDB_PASSWORD),
            live: true,
            pull: {},
            push: {},
            autoStart: true
        });
        replicationState.error$.subscribe(err => {
            console.error('Got replication error:');
            console.dir(err);
        });
    });

    return db;
};

let currentUser = null;

export const recreateDBPromise = () => {
    const newUser = getLoggedInUser().sub;
    if (newUser !== currentUser) {
        dbPromise = _create(newUser);
        currentUser = newUser;
    }
};

export const get = () => {
    recreateDBPromise();
    return dbPromise || _create(getLoggedInUser().sub);
};
