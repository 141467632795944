import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';

import { selectCurrentActivityLogId } from './seesawStopWatchSlice';
import { fetchActivityLogs } from './databaseService';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3A3A3A',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ActivityLog = () => {
    const [rows, setRows] = useState([]);
    const [subs, setSubs] = useState([]);

    const currentActivityLogId = useSelector(selectCurrentActivityLogId);

    useEffect(() => {
        (async () => {
            const sub = await fetchActivityLogs(currentActivityLogId, setRows);
            setSubs([...subs, sub]);
        })();

        return () => {
            subs.forEach((sub) => sub.unsubscribe());
        };
    }, [currentActivityLogId]);

    return (
        <TableContainer component={Paper} sx={{ width: 700 }} >
            <Table sx={{ minWidth: 500 }} aria-label="Activity Log" >
                <TableHead>
                    <TableRow>
                        <StyledTableCell width="30%" align="left">T1</StyledTableCell>
                        <StyledTableCell width="30%" align="left">T2</StyledTableCell>
                        <StyledTableCell width="20%" align="left">Duration</StyledTableCell>
                        <StyledTableCell width="15%" align="left">Activity</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.t1}>
                            <StyledTableCell component="th" scope="row">
                                {row.t1}
                            </StyledTableCell>
                            <StyledTableCell align="left">{row.t2}</StyledTableCell>
                            <StyledTableCell align="left">{row.duration}</StyledTableCell>
                            <StyledTableCell align="left">{row.activity}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ActivityLog;
