export const activityLogsSchema = {
    title: 'ActivityLogs Schema',
    description: 'Seesaw Stopwatch activity logs schema',
    version: 0,
    primaryKey: 'id',
    type: 'object',
    properties: {
        id: {
            type: 'string',
            maxLength: 100
        },
        activityLogs: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    epoch_t1: {
                        type: 'number'
                    },
                    t1: {
                        type: 'string'
                    },
                    epoch_t2: {
                        type: 'number'
                    },
                    t2: {
                        type: 'string'
                    },
                    duration: {
                        type: 'string'
                    },
                    activity: {
                        type: 'string'
                    }
                },
                required: ['epoch_t1', 't1', 'epoch_t2', 't2', 'duration', 'activity']
            }
        }
    },
};

export const contributionGraphSchema = {
    title: 'Contribution Graph Schema',
    description: 'Contribution graph schema',
    version: 0,
    primaryKey: 'id',
    type: 'object',
    properties: {
        id: {
            type: 'string',
            maxLength: 100
        },
        index: {
            type: 'number'
        },
        activity: {
            type: 'string',
            maxLength: 100
        },
        streaks: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    epoch: {
                        type: 'number'
                    },
                    count: {
                        type: 'number'
                    }
                },
                required: ['epoch', 'count']
            }
        }
    },
    required: ['index', 'activity']
};

export const timeTableSchema = {
    title: 'TimeTable Schema',
    description: 'Time tables for scheduled days',
    version: 0,
    primaryKey: 'id',
    type: 'object',
    properties: {
        id: {
            type: 'string',
            maxLength: 100
        },
        day: {
            type: 'string',
            maxLength: 10
        },
        timeTableItems: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: {
                        type: 'string',
                        maxLength: 100
                    },
                    index: {
                        type: 'number'
                    },
                    startTime: {
                        type: 'string'
                    },
                    endTime: {
                        type: 'string'
                    },
                    icon: {
                        type: 'string'
                    },
                    title: {
                        type: 'string'
                    },
                    description: {
                        type: 'string'
                    },
                    showTime: {
                        type: 'boolean'
                    }
                },
                required: ['id', 'index', 'startTime', 'endTime', 'icon', 'title']
            }
        },
        required: ['id', 'day', 'timetables']
    },
};
