import * as Database from '../Database';
import { v4 as uuidv4 } from 'uuid';

export const fetchContributionGraphs = async () => {
    try {
        const db = await Database.get();
        return db.contribution_graph.find().exec();
    } catch (error) {
        console.error('Error fetch contribution graphs', error);
    }
};

export const updateContributionGraphLabel = async (id, newLabel, callback) => {
    try {
        const db = await Database.get();
        const graphCollection = db.contribution_graph;

        const graphDocument = await graphCollection
            .findOne({
                selector: {
                    id
                }
            }).exec();

        if (graphDocument) {
            await graphDocument.incrementalModify((doc) => {
                doc.activity = newLabel;
                return doc;
            });
            callback(id, newLabel);
        } else {
            console.error('No graph found against id = ', id);
        }
    } catch (error) {
        console.error('Error udpate contribution graph label:', error);
    }
}

export const updateContributionGraphsIndices = async (graphs) => {
    try {
        const db = await Database.get();
        const graphCollection = db.contribution_graph;
        const graphDocuments = await graphCollection.find().exec();

        const existingGraphIds = graphDocuments.map(doc => doc.id);

        // Delete activities missing from values
        const graphIdsToDelete = existingGraphIds.filter(id => !graphs.some(graph => graph.id === id));
        await Promise.all(graphIdsToDelete.map(async (id) => {
            const graphDocument = graphDocuments.find(doc => doc.id === id);
            await graphDocument.incrementalRemove({ id });
        }));

        await Promise.all(graphs.map(async ({ id, index, activity }) => {
            const graphDocument = graphDocuments.find(doc => doc.id === id);
            if (!graphDocument) {
                // Add activities missing from db
                await graphCollection.insert({
                    id,
                    index,
                    activity,
                    streaks: []
                });
            } else {
                await graphDocument.incrementalModify((doc) => {
                    doc.index = index;
                    return doc;
                });
            }
        }));
    } catch (error) {
        console.error('Error udpate contribution graphs:', error);
    }
};

export const fetchStreaks = async (id, initialValues, setValues) => {
    try {
        const db = await Database.get();
        const sub = db.contribution_graph
            .findOne({
                selector: {
                    id
                }
            }).$.subscribe((graphDocument) => {
                if (!graphDocument) {
                    setValues(initialValues);
                } else {
                    const updatedValues = initialValues.map((value) => {
                        const matchingStreak = graphDocument.streaks.find((streak) => streak.epoch === value.epoch);
                        if (matchingStreak) {
                            return {
                                ...value,
                                count: matchingStreak.count
                            };
                        }
                        return value;
                    });
                    setValues(updatedValues);
                }
            });
        return sub;
    } catch (error) {
        console.error('Error fetch streaks:', error);
    }
};

export const upsertStreaks = async (id, values, value) => {
    try {
        const db = await Database.get();
        const graphCollection = db.contribution_graph;

        const graphDocument = await graphCollection
            .findOne({
                selector: {
                    id
                }
            }).exec();

        if (graphDocument) {
            const epoch = value.epoch;
            const date = value.date;
            const count = value.count === 1 ? 0 : 1;
            const newValue = { epoch, date, count };

            await graphDocument.incrementalModify((doc) => {
                const existingEntry = doc.streaks.find(entry => entry.epoch === epoch);
                if (existingEntry) {
                    doc.streaks = doc.streaks.map(entry => {
                        if (entry.epoch === epoch) {
                            return newValue;
                        }
                        return entry;
                    });
                } else {
                    doc.streaks.push(newValue);
                }
                return doc;
            });

            const rest = values.filter(v => v.epoch !== epoch);
            return [...rest, newValue];
        } else {
            // Ideally this case would never be reached simply because all contribution graphs 
            // are initialised with a label and an empty streak!
            console.log('Not found contributionGraphId = ', id);
            return values;
        }
    } catch (error) {
        console.error('Error upsert streaks:', error);
    }
};
