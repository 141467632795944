import React, { forwardRef, useState, useCallback, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';

import { ContributionGraphsContainer } from "./StyledComponents";
import DraggableContributionGraph from './DraggableContributionGraph';
import { getRange, shiftDate } from './utils';
import {
    fetchContributionGraphs,
    updateContributionGraphsIndices,
    updateContributionGraphLabel
} from './databaseService';

import withAuthentication from '../withAuthentication';
import "./react-calendar-heatmap.css";

const ContributionGraphs = () => {

    const createDefaultContributionGraph = (id, label) => ({
        id,
        label,
        startDate: null,
        endDate: null,
        initialValues: null,
        classForValue: null,
        tooltipDataAttrs: null,
        onClick: null,
        progress: null,
        disableDelete: false
    });

    const createYearProgressGraph = (id, label) => {
        const currentYear = new Date().getFullYear();
        const startDate = new Date(`${currentYear - 1}-12-31`);
        const endDate = new Date(`${currentYear}-12-31`);
        const initialValues = getRange(366).map((index) => {
            const date = shiftDate(endDate, -index);
            return {
                epoch: date.getTime(),
                date,
                count: date <= new Date() ? 1 : 0
            };
        });
        const countOfOnes = initialValues.filter(item => item.count === 1).length;
        const totalCount = initialValues.length;
        const progress = Math.round((countOfOnes / totalCount) * 100) + '%';

        return {
            id,
            label,
            startDate: startDate,
            endDate: endDate,
            initialValues,
            classForValue: (value) => {
                if (!value || value.count === 0) {
                    return "color-empty";
                }
                return "color-year-progress";
            },
            tooltipDataAttrs: null,
            onClick: () => { },
            progress: progress,
            disableDelete: true
        }
    };

    const [contributionGraphs, setContributionGraphs] = useState([]);

    useEffect(() => {
        (async () => {
            const graphDocuments = await fetchContributionGraphs();
            const graphs = graphDocuments.map(({ id, index, activity }) => ({ id, index, activity }));
            graphs.sort((a, b) => a.index - b.index);
            setContributionGraphs([
                ...graphs.map(({ id, activity }) => {
                    if (!Number.isNaN(Number(activity))) {
                        return createYearProgressGraph(id, activity);
                    } else {
                        return createDefaultContributionGraph(id, activity);
                    }
                })
            ]);
        })();
    }, []);

    const moveContributionGraph = useCallback((dragIndex, hoverIndex) => {
        setContributionGraphs((prevContributionGraphs) =>
            update(prevContributionGraphs, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevContributionGraphs[dragIndex]],
                ],
            }));
    }, []);

    useEffect(() => {
        const newIndices = contributionGraphs.map((graph, index) => ({ id: graph.id, index, activity: graph.label }));
        if (newIndices.length !== 0) {
            (async () => {
                await updateContributionGraphsIndices(newIndices);
            })();
        }
    }, [contributionGraphs]);

    const addContributionGraph = (index) => {
        setContributionGraphs(prevContributionGraphs => {
            let highestIndex = -1;
            prevContributionGraphs.forEach(graph => {
                if (graph.label.startsWith('New Graph')) {
                    const indexStr = graph.label.replace('New Graph', '').trim();
                    const indexNum = parseInt(indexStr);
                    if (!isNaN(indexNum) && indexNum > highestIndex) {
                        highestIndex = indexNum;
                    }
                }
            });

            const newGraphLabel = `New Graph ${highestIndex + 1}`;
            const newGraph = createDefaultContributionGraph(uuidv4(), newGraphLabel);

            const newGraphs = [...prevContributionGraphs];
            newGraphs.splice(index + 1, 0, newGraph);
            for (let i = index + 2; i < newGraphs.length; i++) {
                newGraphs[i].index = i;
            }
            return newGraphs;
        });
    };

    const deleteContributionGraph = (indexToDelete) => {
        setContributionGraphs(prevContributionGraphs =>
            prevContributionGraphs.filter((_, index) => index !== indexToDelete)
                .map((graph, newIndex) => ({ ...graph, index: newIndex }))
        );
    };

    const updateContributionGraph = (id, newLabel) => {
        (async () => {
            await updateContributionGraphLabel(id, newLabel, (id, newLabel) => {
                setContributionGraphs(prevContributionGraphs =>
                    prevContributionGraphs.map((graph) => {
                        if (graph.id === id) {
                            return { ...graph, label: newLabel };
                        }
                        return graph;
                    })
                );
            });
        })();
    };

    const renderContributionGraph = useCallback((graph, index) => {
        return (
            <DraggableContributionGraph
                key={graph.id}
                id={graph.id}
                index={index}
                moveContributionGraph={moveContributionGraph}
                label={graph.label}
                startDate={graph.startDate}
                endDate={graph.endDate}
                initialValues={graph.initialValues}
                classForValue={graph.classForValue}
                tooltipDataAttrs={graph.tooltipDataAttrs}
                onClick={graph.onClick}
                progress={graph.progress}
                disableDelete={graph.disableDelete}
                addContributionGraph={addContributionGraph}
                deleteContributionGraph={deleteContributionGraph}
                updateContributionGraph={updateContributionGraph}
            />
        )
    }, []);

    return (
        <DndProvider backend={HTML5Backend}>
            <ContributionGraphsContainer>
                {contributionGraphs.map((graph, i) => renderContributionGraph(graph, i))}
            </ContributionGraphsContainer>
        </DndProvider>
    );
};

export default withAuthentication(ContributionGraphs);
