import styled from 'styled-components';

export const TimeTableContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
`;

export const TimeTableMenuContainer = styled.div`
    display: flex; 
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: fixed;
    right: 20px;
    top: calc(50% - 150px);
    margin-right: 20px;
`;

export const StyledTimeTableItemTitle = styled.input`
    font-size: 19px;
    font-weight: 400;
    width: 100%;
    text-align: ${props => (props.$index % 2 === 0 ? 'left' : 'right')};
    border: none; /* Remove borders */
    outline: none; /* Remove focus outline */
    cursor: text; /* Change cursor to indicate text can be edited */
    color: '#D72525';
    background-color: 'transparent';
`;

export const StyledTimeTableItemDescription = styled.textarea`
    /* Reset all default styles */
    appearance: none;
    border: none;
    margin: 0;
    padding: 0;
    resize: none;
    outline: none;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    width: 100%;
    height: 100%;

    text-align: ${props => (props.$index % 2 === 0 ? 'left' : 'right')};
    cursor: text; /* Change cursor to indicate text can be edited */
    color: grey;
`;
