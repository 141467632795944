import React, { useState, useRef } from 'react';

import { AVAILABLE_ICONS } from './constants';
import { upsertTimeTableItem } from './databaseService';
import VisibilitySnackbar from '../VisibilitySnackbar';
import { StyledTimeTableItemTitle } from './StyledComponents';
import { CONTAINS_LETTERS_REGEX, VALID_TEXT_REGEX } from '../constants';

const TimeTableItemTitle = ({
    timeTableId,
    index,
    id,
    startTime,
    endTime,
    icon,
    title: initialTitle,
    description,
    showTime
}) => {
    const [title, setTitle] = useState(initialTitle);
    const inputRef = useRef(null);

    const [snackbarState, setSnackbarState] = useState({
        open: false,
        severity: 'error',
        message: ''
    });

    const save = async (title) => {
        if (title === initialTitle) {
            inputRef.current.blur();
            return;
        }
        if (title.length === 0) {
            setSnackbarState({
                open: true,
                severity: 'error',
                message: 'Empty title is not allowed. Please enter a valid label'
            });
        } else if (!title.match(CONTAINS_LETTERS_REGEX)) {
            setSnackbarState({
                open: true,
                severity: 'error',
                message: 'Title must contain at least 1 letter'
            });
        } else if (!title.match(VALID_TEXT_REGEX)) {
            setSnackbarState({
                open: true,
                severity: 'error',
                message: 'Invalid title. Please enter a title containing only letters, numbers, -, \', and _'
            });
        } else {
            const iconIndex = AVAILABLE_ICONS.findIndex(({ id }) => id === icon);
            await upsertTimeTableItem(
                timeTableId,
                id,
                startTime,
                endTime,
                AVAILABLE_ICONS[iconIndex].id,
                title,
                description,
                showTime)
                ;
            setSnackbarState({
                open: true,
                severity: 'success',
                message: 'Title updated successfully'
            });
            inputRef.current.blur();
        }
    };

    return (
        <>
            <VisibilitySnackbar
                state={snackbarState}
                onClose={() => {
                    setSnackbarState({
                        open: false,
                        severity: snackbarState.severity,
                        message: snackbarState.message
                    })
                }}
            />
            <StyledTimeTableItemTitle
                ref={inputRef}
                $index={index}
                type="text"
                value={title}
                onChange={(e) => {
                    setTitle(e.target.value)
                }}
                onBlur={(e) => {
                    save(e.target.value);
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        save(e.target.value);
                    }
                }}
            />
        </>
    );
}

export default TimeTableItemTitle;
