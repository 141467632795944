import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, useLocation } from 'react-router-dom';

import { TimeTableMenuContainer } from './StyledComponents';
import { SCHEDULE_DAY } from './constants';

const { WFO, WFH, SATURDAY, SUNDAY, HOLIDAY } = SCHEDULE_DAY;

const TimeTableMenuItem = ({ iconPath, onClick, active }) => {
    return (
        <IconButton
            sx={{
                color: "#444444",
                backgroundColor: active ? "#EEF4FF" : "transparent",
                fontFamily: "Kanit, sans-serif",
                fontSize: 14,
                padding: 1.4
            }}
            onClick={onClick}
        >
            <Box
                component="img"
                sx={{ height: 28 }}
                alt="MenuLogo"
                src={iconPath}
            />
        </IconButton>
    );
};

const TimeTableMenu = ({ saveActiveDay, activeDay }) => {
    const navigate = useNavigate();

    const handleOnClick = (day) => {
        saveActiveDay(day);
        navigate(`/timetables/${day}`);
    };

    return (
        <TimeTableMenuContainer>
            <Tooltip title="WFO Schedule" placement="left" arrow>
                <div><TimeTableMenuItem iconPath="/wfo.png" onClick={() => handleOnClick(WFO)} active={activeDay === WFO} /></div>
            </Tooltip>
            <Tooltip title="WFH Schedule" placement="left" arrow>
                <div><TimeTableMenuItem iconPath="/wfh.png" onClick={() => handleOnClick(WFH)} active={activeDay === WFH} /></div>
            </Tooltip>
            <Tooltip title="Saturday Schedule" placement="left" arrow>
                <div><TimeTableMenuItem iconPath="/saturday.png" onClick={() => handleOnClick(SATURDAY)} active={activeDay === SATURDAY} /></div>
            </Tooltip>
            <Tooltip title="Sunday Schedule" placement="left" arrow>
                <div><TimeTableMenuItem iconPath="/sunday.png" onClick={() => handleOnClick(SUNDAY)} active={activeDay === SUNDAY} /></div>
            </Tooltip>
            <Tooltip title="Holiday Schedule" placement="left" arrow>
                <div><TimeTableMenuItem iconPath="/holiday.png" onClick={() => handleOnClick(HOLIDAY)} active={activeDay === HOLIDAY} /></div>
            </Tooltip>
        </TimeTableMenuContainer>
    );
}

export default TimeTableMenu;
