import React, { useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('loggedInVisibilityUser');
        if (isLoggedIn) {
            navigate('/');
        }
    }, [navigate]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
            <img src={`/logo.png`} alt="Logo" style={{ height: 48, marginBottom: 16 }} />
            <h3>Welcome to Visibility Dashboard</h3>
            <p>Please sign in to continue</p>
            <GoogleLogin
                onSuccess={credentialResponse => {
                    localStorage.setItem('loggedInVisibilityUser', credentialResponse.credential);
                    window.location.reload();
                    navigate('/');
                }}
                onError={() => {
                    console.err('Sign in Failed');
                }}
            />
        </div>
    );
};

export default SignIn;
