import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Caption from './Caption';
import {
    Container,
    TimersContainer,
    TimerContainer,
    TimerDisplay,
    ResponsiveTimerButton,
    TimerButtonsContainter
} from './StyledComponents';
import {
    selectStyle,
    selectSeesawStopwatchInProgress,
    setInProgress,
    selectCurrentActivityLogId,
    createNewActivityLogId,
} from '../seesawStopWatchSlice';
import { timerWorker } from './timerWorker';
import WorkerBuilder from '../../WorkerBuilder';
import { upsertActivityLogs } from '../databaseService';

const Timer = () => {
    const style = useSelector(selectStyle);
    const currentActivityLogId = useSelector(selectCurrentActivityLogId);
    const inProgress = useSelector(selectSeesawStopwatchInProgress);

    const dispatch = useDispatch();

    const timerRef = useRef(null);
    const counterRef = useRef(null);

    const [clearLogsOnStart, setClearLogsOnStart] = useState(false);

    const startTimer = () => {
        worker.postMessage({ command: 'start' });
        if (clearLogsOnStart) {
            dispatch(createNewActivityLogId());
        }
        if (!inProgress) dispatch(setInProgress(true));
    };

    const pauseTimer = () => {
        worker.postMessage({ command: 'pause' });
    };

    const finishTimer = () => {
        worker.postMessage({ command: 'finish' });
        dispatch(setInProgress(false));
    };

    const [worker, setWorker] = useState(null);

    const updateDisplay = (ref, { hours, minutes, seconds }) => {
        var formattedHours = hours < 10 ? "0" + hours : hours;
        var formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
        var formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
        ref.current.textContent = formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
    };

    const workerMessageHandler = async ({ data }) => {
        switch (data.type) {
            case 'timer':
                updateDisplay(timerRef, data.time);
                break;
            case 'counter':
                updateDisplay(counterRef, data.time);
                break;
            case 'activityRows':
                data.log.activity = data.log.active ? localStorage.getItem('active-timer') : localStorage.getItem('inactive-timer');
                delete data.log.active;
                await upsertActivityLogs(currentActivityLogId, data);
                break;
            case 'clearLogs':
                setClearLogsOnStart(true);
                break;
            default:
                console.error('Timer type is not recognized: ', data.type);
        }
    };

    useEffect(() => {
        const worker = new WorkerBuilder(timerWorker);
        worker.onmessage = workerMessageHandler;
        setWorker(worker);
        return () => {
            worker.terminate();
        };
    }, []);

    useEffect(() => {
        if (worker) {
            worker.onmessage = workerMessageHandler;
        }
    }, [currentActivityLogId]);

    useEffect(() => {
        const handleWindowClose = (event) => {
            if (inProgress) {
                event.preventDefault();
                event.returnValue = 'Seesaw Stopwatch is still running. Are you sure you want to leave?';
            }
        };

        window.addEventListener('beforeunload', handleWindowClose);

        return () => {
            window.removeEventListener('beforeunload', handleWindowClose);
        };
    }, [inProgress]);

    return (
        <Container>
            <TimersContainer>
                <TimerContainer>
                    <Caption id='active-timer' defaultCaption='Study' />
                    <TimerDisplay ref={timerRef}>00:00:00</TimerDisplay>
                </TimerContainer>
                <TimerContainer>
                    <Caption id='inactive-timer' defaultCaption='Break' />
                    <TimerDisplay ref={counterRef}>00:00:00</TimerDisplay>
                </TimerContainer>
            </TimersContainer>
            <TimerButtonsContainter>
                <ResponsiveTimerButton style={style} onClick={startTimer}>
                    Start
                </ResponsiveTimerButton>
                <ResponsiveTimerButton style={style} onClick={pauseTimer}>
                    Pause
                </ResponsiveTimerButton>
                <ResponsiveTimerButton style={style} onClick={finishTimer} >
                    Finish
                </ResponsiveTimerButton>
            </TimerButtonsContainter>
        </Container>
    );
}

export default Timer;
