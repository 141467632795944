import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Menu, MenuItem } from '@mui/material';
import { Avatar } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import LogoutIcon from '@mui/icons-material/Logout';
import { googleLogout } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

const VisibilityAppBar = () => {
    const [profilePicture, setProfilePicture] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const loggedInUser = jwtDecode(localStorage.getItem('loggedInVisibilityUser'));
        setProfilePicture(loggedInUser.picture);
    }, []);

    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        localStorage.removeItem('loggedInVisibilityUser');
        googleLogout();
        handleClose();
        navigate('/');
    };

    return (
        <AppBar
            position="fixed"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: "#FCFCFC" }}
            elevation={0}
        >
            <Toolbar>
                <Box
                    component="img"
                    sx={{ height: 36 }}
                    alt="Logo"
                    src={"/logo.png"}
                />
                <Typography
                    component="div"
                    sx={{
                        flexGrow: 1,
                        color: "#323232",
                        fontFamily: "Kanit, sans-serif",
                        fontSize: 26,
                        marginLeft: 1,
                        marginTop: 1
                    }}
                >
                    Visibility
                </Typography>
                <Avatar
                    alt="Profile Picture"
                    src={profilePicture}
                    sx={{
                        width: 32,
                        height: 32,
                        fontSize: 16,
                        backgroundColor: '#757575',
                        marginLeft: 1
                    }}
                    onClick={handleAvatarClick}
                />
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={handleLogout}
                        sx={{ fontSize: 14, minWidth: 'auto' }}
                    >
                        <LogoutIcon sx={{ mr: 1, fontSize: 16 }} />
                        Sign out
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}

export default VisibilityAppBar;
