import * as React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import TimerRoundedIcon from '@mui/icons-material/TimerRounded';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { Link, Outlet } from 'react-router-dom';

import VisibilityAppBar from './VisibilityAppBar';
import { selectSeesawStopwatchInProgress } from './seesaw-stopwatch/seesawStopWatchSlice';
import withAuthentication from './withAuthentication';

const iconSx = { width: '30px', height: '30px', color: '#323232' };

const VisibilityDrawer = () => {
    const seesawStopwatchInProgress = useSelector(selectSeesawStopwatchInProgress);

    const renderListItem = (icon, path, tooltip) => (
        <Tooltip title={tooltip} placement="right" arrow>
            <ListItemButton
                sx={{ minHeight: 48, justifyContent: 'initial', px: 2.5 }}
                component={Link}
                to={path}
            >
                <ListItemIcon sx={{ minWidth: 0, justifyContent: 'center' }}>
                    {icon}
                </ListItemIcon>
            </ListItemButton>
        </Tooltip>

    );

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <VisibilityAppBar />
            <Drawer
                variant="permanent"
                sx={{ backgroundColor: 'red' }}
                PaperProps={{
                    sx: {
                        backgroundColor: "#F9FEFF",
                        border: 0,
                    }
                }}
            >
                <Toolbar />
                <List>
                    <ListItem key="seesaw-stopwatch" disablePadding sx={{ display: 'block' }}>
                        {renderListItem(<TimerRoundedIcon sx={{ ...iconSx, color: seesawStopwatchInProgress ? '#D61F1F' : '#323232' }} />, '/stopwatch', 'Seesaw Stopwatch')}
                    </ListItem>
                    <ListItem key="contribution-graphs" disablePadding sx={{ display: 'block' }}>
                        {renderListItem(<BarChartRoundedIcon sx={iconSx} />, '/contribution-graphs', 'Contribution Graphs')}
                    </ListItem>
                    <ListItem key="timetables" disablePadding sx={{ display: 'block' }}>
                        {renderListItem(<AccessTimeFilledRoundedIcon sx={iconSx} />, '/timetables', 'Time Tables')}
                    </ListItem>
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <Outlet />
            </Box>
        </Box>
    );
}

export default withAuthentication(VisibilityDrawer);
