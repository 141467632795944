import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import PetsIcon from '@mui/icons-material/Pets';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import CommuteIcon from '@mui/icons-material/Commute';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

export const SCHEDULE_DAY = {
  WFO: "wfo",
  WFH: "wfh",
  SATURDAY: "saturday",
  SUNDAY: "sunday",
  HOLIDAY: "holiday",
};

export const isActiveDayValid = (activeDay) => {
  return Object.values(SCHEDULE_DAY).includes(activeDay);
};

export const ITEM_TYPES = {
  TIME_TABLE_ITEM: 'timetable-item',
};

export const AVAILABLE_ICONS = [
  { id: 'local_library', icon: <LocalLibraryIcon /> },
  { id: 'commute', icon: <CommuteIcon /> },
  { id: 'headphones', icon: <HeadphonesIcon /> },
  { id: 'restaurant', icon: <RestaurantIcon /> },
  { id: 'fastfood', icon: <FastfoodIcon /> },
  { id: 'microwave', icon: <MicrowaveIcon /> },
  { id: 'laptop_mac', icon: <LaptopMacIcon /> },
  { id: 'hotel', icon: <HotelIcon /> },
  { id: 'pets', icon: <PetsIcon /> },
  { id: 'fitness_center', icon: <FitnessCenterIcon /> },
  { id: 'visibility', icon: <VisibilityIcon /> },
  { id: 'self_improvement', icon: <SelfImprovementIcon /> },
  { id: 'family', icon: <FamilyRestroomIcon /> },
  { id: 'default', icon: <QuestionMarkIcon /> }
];
