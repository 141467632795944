import React, { useState, useRef, forwardRef } from 'react';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemIcon from '@mui/material/ListItemIcon';

import { upsertTimeTableItem, removeTimeTableItem } from './databaseService';
import TimeTableItemTitle from './TimeTableTitle';
import TimeTableItemDescription from './TimeTableItemDescription';
import { AVAILABLE_ICONS } from './constants';
import TimeTableMeter from './TimeTableMeter';
import TimeTableItemTimeSlot from './TimeTableItemTimeSlot';

const TimeTableItem = forwardRef(({ index, invalid, id, timeTableId, startTime, endTime, icon, title, description, showTime = true }, ref) => {

    const [isHighlighted, setIsHighlighted] = useState(false);
    const [contextMenu, setContextMenu] = useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const highlightedDotRef = useRef(null);

    const scrollToHighlightedDot = () => {
        if (highlightedDotRef.current) {
            highlightedDotRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleDotClick = async () => {
        const iconIndex = (AVAILABLE_ICONS.findIndex(({ id }) => id === icon) + 1) % AVAILABLE_ICONS.length;
        await upsertTimeTableItem(
            timeTableId,
            id,
            startTime,
            endTime,
            AVAILABLE_ICONS[iconIndex].id,
            title,
            description,
            showTime
        );
    };

    return (
        <>
            <TimelineItem
                ref={ref}
                onContextMenu={handleContextMenu}
            >
                {showTime && (
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align="right"
                        variant="body2"
                    >
                        <TimeTableItemTimeSlot
                            invalid={invalid}
                            timeTableId={timeTableId}
                            id={id}
                            startTime={startTime}
                            endTime={endTime}
                            icon={icon}
                            title={title}
                            description={description}
                            showTime={showTime}
                        />
                        {
                            startTime.length !== 0 &&
                            endTime.length !== 0 &&
                            <TimeTableMeter
                                startTime={startTime}
                                endTime={endTime}
                                setIsHighlighted={(isHighlighted) => setIsHighlighted(isHighlighted)}
                            />
                        }
                    </TimelineOppositeContent>
                )}

                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                        ref={isHighlighted ? highlightedDotRef : null}
                        sx={{
                            background: isHighlighted ? '#3783DA' : '#CBCBCB',
                            transform: isHighlighted ? 'scale(1.2)' : 'scale(1)',
                            boxShadow: isHighlighted ? '0px 0px 10px #C1C1C1' : 'none',
                        }}
                        onClick={handleDotClick}

                    >
                        {(
                            AVAILABLE_ICONS.find(({ id }) => id === icon) ||
                            AVAILABLE_ICONS.find(({ id }) => id === 'default')
                        ).icon}
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <TimeTableItemTitle
                        timeTableId={timeTableId}
                        index={index}
                        id={id}
                        startTime={startTime}
                        endTime={endTime}
                        icon={icon}
                        title={title}
                        description={description}
                        showTime={showTime}
                    />
                    <TimeTableItemDescription
                        timeTableId={timeTableId}
                        index={index}
                        id={id}
                        startTime={startTime}
                        endTime={endTime}
                        icon={icon}
                        title={title}
                        description={description}
                        showTime={showTime}
                    />
                </TimelineContent>
            </TimelineItem>
            <Menu
                open={contextMenu !== null}
                onClose={() => setContextMenu(null)}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <MenuItem onClick={async () => {
                    await removeTimeTableItem(timeTableId, id);
                    setContextMenu(null);
                }}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="medium" sx={{ color: '#FF3333' }} />
                    </ListItemIcon>
                    Delete
                </MenuItem>
            </Menu>
        </>
    );
});

export default TimeTableItem;
