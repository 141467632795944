import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const PageNotFoundOuterContainer = styled.div`
  position: relative;
  height: 100vh;
`;

const PageNotFoundInnerContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 920px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
`;

const HttpErrorCodeContainer = styled.div`
  position: absolute;
  height: 100px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
`;

const HttpErrorCode = styled.h1`
  font-family: 'maven pro', sans-serif;
  color: #ececec;
  font-weight: 900;
  font-size: 276px;
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const PageNotFoundMessageHeader = styled.h2`
  font-family: 'maven pro', sans-serif;
  font-size: 46px;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
`;

const PageNotFoundMessageBody = styled.p`
  font-family: 'maven pro', sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  margin-top: 15px;
`;

const PageNotFoundButton = styled.button`
  font-family: 'maven pro', sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #189cf0;
  display: inline-block;
  padding: 14px 24px;
  border: 2px solid transparent;
  border-radius: 40px;
  color: #fff;
  font-weight: 400;
  transition: .2s all;
  cursor: pointer;

  &:hover {
    background-color: #46AFF1;
    border-color: #46AFF1;
  }
`;

const PageNotFound = () => {
  const navigate = useNavigate();

    return (
        <PageNotFoundOuterContainer>
            <PageNotFoundInnerContainer>
                <HttpErrorCodeContainer>
                    <HttpErrorCode>404</HttpErrorCode>
                </HttpErrorCodeContainer>
                <PageNotFoundMessageHeader>We are sorry, Page not found!</PageNotFoundMessageHeader>
                <PageNotFoundMessageBody>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</PageNotFoundMessageBody>
                <PageNotFoundButton onClick={() => navigate('/')}>
                    Back To Homepage
                </PageNotFoundButton>
            </PageNotFoundInnerContainer>
        </PageNotFoundOuterContainer>
    );
};

export default PageNotFound;
