import React, { useEffect, useRef, useState } from 'react';

import { StyledCaption } from './StyledComponents';

const Caption = ({ id, defaultCaption }) => {
    const [caption, setCaption] = useState(defaultCaption);
    const inputRef = useRef(null);

    function loadCaption() {
        var savedCaption = localStorage.getItem(id);
        if (savedCaption) {
            setCaption(savedCaption);
        } else {
            localStorage.setItem(id, defaultCaption);
        }
    }

    function saveCaption(newCaption) {
        setCaption(newCaption);
        localStorage.setItem(id, newCaption);
        inputRef.current.blur();
    }

    useEffect(() => {
        loadCaption();
    }, []);

    return (
        <StyledCaption
            ref={inputRef}
            type="text"
            value={caption}
            onChange={(e) => {
                setCaption(e.target.value)
            }}
            onBlur={(e) => {
                saveCaption(e.target.value);
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    saveCaption(e.target.value);
                }
            }}
        />
    );
}

export default Caption;
