import styled from 'styled-components';

import {
    SeesawStopWatchButton as SeesawStopWatchButton,
    mobileStylesForButtons,
    smallMobileStylesForButtons
} from '../StyledComponents';

export const Container = styled.div`
    margin-top: 50px;
    margin-bottom: 100px;
`;

export const TimersContainer = styled.div`
    display: flex;
    /* Use flexbox to arrange child elements side by side */
    justify-content: center;
    /* Center the child elements horizontally */
    align-items: center;
    /* Center the child elements vertically */
    flex-wrap: wrap;
    /* Allow items to wrap onto the next line */
    height: 100%;
    width: 100%;
`;

export const TimerContainer = styled.div`
    text-align: center;
    margin: 0 20px;
    /* Adjust the space between the timer containers */
    top: 50%;
    left: 50%;
`;

export const StyledCaption = styled.input`
    font-size: 16px;
    text-align: center;
    margin-bottom: 5px;
    border: none; /* Remove borders */
    outline: none; /* Remove focus outline */
    cursor: text; /* Change cursor to indicate text can be edited */
    color: '#D72525';
    background-color: 'transparent';
`;

export const TimerDisplay = styled.div`
    color: #3F3F3F;
    font-size: 90px;
    margin-bottom: 10px;
`;

export const TimerButtonsContainter = styled.div``;

export const ResponsiveTimerButton = styled(SeesawStopWatchButton)`
    ${mobileStylesForButtons}
    ${smallMobileStylesForButtons}
`;
