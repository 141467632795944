import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Routes, Route, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import TimeTables from "./time-tables/TimeTables";
import SeesawStopWatch from "./seesaw-stopwatch/SeesawStopWatch";
import ContributionGraphs from "./contribution-graphs/ContributionGraphs";
import VisibilityDrawer from "./VisibilityDrawer";
import './App.css';
import SignIn from "./SignIn";
import PageNotFound from "./PageNotFound";

const App = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <HelmetProvider>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;600;700&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <Routes>
          <Route path="/" element={<Navigate to="/stopwatch" />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/*" element={<VisibilityDrawer />}>
            <Route path="stopwatch" exact element={<SeesawStopWatch />} />
            <Route path="contribution-graphs" exact element={<ContributionGraphs />} />
            <Route path="timetables" element={<TimeTables />} />
            <Route path="timetables/:activeDay" element={<TimeTables />} />
            {/* <Route path="*" element={<Navigate to="/404" />} /> */}
          </Route>
          <Route path="/404" element={<PageNotFound />} />
        </Routes>
      </HelmetProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
