import React, { useRef, useState } from 'react';

import { StyledContributionGraphLabel } from './StyledComponents';
import VisibilitySnackbar from '../VisibilitySnackbar';
import { fetchContributionGraphs } from './databaseService';
import { CONTAINS_LETTERS_REGEX, VALID_TEXT_REGEX } from '../constants';

const ContributionGraphLabel = ({ graphId, initialLabel, updateContributionGraph }) => {
    const [label, setLabel] = useState(initialLabel);
    const inputRef = useRef(null);

    const [snackbarState, setSnackbarState] = useState({
        open: false,
        severity: 'error',
        message: ''
    });

    const save = async (newLabel) => {
        if (newLabel === initialLabel) {
            inputRef.current.blur();
            return;
        }
        if (newLabel.length === 0) {
            setSnackbarState({
                open: true,
                severity: 'error',
                message: 'Empty label is not allowed. Please enter a valid label'
            });
        } else if (!newLabel.match(CONTAINS_LETTERS_REGEX)) {
            setSnackbarState({
                open: true,
                severity: 'error',
                message: 'Label must contain at least 1 letter'
            });
        } else if (!newLabel.match(VALID_TEXT_REGEX)) {
            setSnackbarState({
                open: true,
                severity: 'error',
                message: 'Invalid label. Please enter a label containing only letters, numbers, -, and _'
            });
        } else {
            const graphDocuments = await fetchContributionGraphs();
            const activities = graphDocuments.map(({ activity }) => activity);
            if (activities.includes(newLabel)) {
                setSnackbarState({
                    open: true,
                    severity: 'error',
                    message: 'Contribution graph with this label already exists. Please choose a unique label.'
                });
            } else {
                setLabel(newLabel);
                updateContributionGraph(graphId, newLabel);
                setSnackbarState({
                    open: true,
                    severity: 'success',
                    message: 'Contribution graph label updated successfully'
                });
                inputRef.current.blur();
            }
        }
    };

    return (
        <>
            <VisibilitySnackbar
                state={snackbarState}
                onClose={() => {
                    setSnackbarState({
                        open: false,
                        severity: snackbarState.severity,
                        message: snackbarState.message
                    })
                }} />
            <StyledContributionGraphLabel
                ref={inputRef}
                type="text"
                value={label}
                onChange={(e) => {
                    setLabel(e.target.value)
                }}
                onBlur={(e) => {
                    save(e.target.value);
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        save(e.target.value);
                    }
                }}
            />
        </>
    );
}

export default ContributionGraphLabel;
