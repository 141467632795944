import React, { useState, useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';

import DraggableTimeTableItem from './DraggableTimeTableItem';
import { upsertTimeTableItem, updateTimeTable } from './databaseService';
import { AVAILABLE_ICONS } from './constants';

const TimeTable = ({ timeTableData }) => {

    const [timeTableItems, setTimeTableItems] = useState(timeTableData.timeTableItems);
    useEffect(() => {
        setTimeTableItems(timeTableData.timeTableItems);
    }, [timeTableData]);

    const [repeatIcon, setRepeatIcon] = useState({
        bgColor: '#CBCBCB',
        icon: <RepeatIcon />
    });

    const [invalidTimeTableItemIndices, setInvalidTimeTableItemIndices] = useState(new Set());

    const renderTimelineItem = (index, id, startTime, endTime, icon, title, description, showTime = true) => {
        const invalid = invalidTimeTableItemIndices.has(index);
        return (
            <DraggableTimeTableItem
                key={id}
                index={index}
                invalid={invalid}
                id={id}
                timeTableId={timeTableData.id}
                startTime={startTime}
                endTime={endTime}
                icon={icon}
                title={title}
                description={description}
                showTime={showTime}
                moveTimeTableItem={moveTimeTableItem}
            />
        );
    };

    const moveTimeTableItem = useCallback((dragIndex, hoverIndex) => {
        setTimeTableItems((prevTimeTableItems) =>
            update(prevTimeTableItems, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevTimeTableItems[dragIndex]],
                ],
            }),
        )
    }, []);

    const checkTimeTableValidity = (timeTableItems) => {
        const newIndices = new Set();
        for (let i = 1; i < timeTableItems.length; i++) {
            const prevItem = timeTableItems[i - 1];
            const currentItem = timeTableItems[i];

            const prevEndTime = dayjs(prevItem.endTime, 'h:mm a');
            const currentStartTime = dayjs(currentItem.startTime, 'h:mm a');

            if (currentStartTime.isBefore(prevEndTime)) {
                newIndices.add(i - 1);
                newIndices.add(i);
                console.error(`Timetable items ${prevItem.title} at index = ${i - 1} and ${currentItem.title} at index = ${i} are overlapping or not in ascending order.`);
            }
        }
        setInvalidTimeTableItemIndices(newIndices);
    };

    useEffect(() => {
        if (timeTableItems.length !== 0) {
            checkTimeTableValidity(timeTableItems);
            (async () => {
                await updateTimeTable(timeTableData.id, timeTableItems);
            })();
        }
    }, [timeTableItems]);

    return (
        <DndProvider backend={HTML5Backend}>
            <Box
                style={{
                    width: 900,
                    display: 'flex',
                    margin: 'auto',
                    marginTop: '20px',
                    alignItems: 'center',
                }}
            >
                <Timeline position="alternate">
                    {timeTableItems.map((item, index) => (
                        renderTimelineItem(
                            index,
                            item.id,
                            item.startTime,
                            item.endTime,
                            item.icon,
                            item.title,
                            item.description,
                            item.showTime
                        )
                    ))}
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot
                                sx={{ background: repeatIcon.bgColor }}
                                onMouseEnter={() => setRepeatIcon({
                                    bgColor: '#33A406',
                                    icon: <AddIcon />
                                })}
                                onMouseLeave={() => setRepeatIcon({
                                    bgColor: '#CBCBCB',
                                    icon: <RepeatIcon />
                                })}
                                onClick={() => {
                                    upsertTimeTableItem(
                                        timeTableData.id,
                                        uuidv4(),
                                        '',
                                        '',
                                        AVAILABLE_ICONS.find(({ id }) => id === 'default').id,
                                        'New entry',
                                        'The way we do anything is the way we do everything!',
                                        true
                                    );
                                }}
                            >
                                {repeatIcon.icon}
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span">
                                Repeat
                            </Typography>
                            <Typography color="text.secondary">Because this is the life you love!</Typography>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            </Box>
        </DndProvider>
    );
};

export default TimeTable;
