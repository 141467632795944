import React, { useRef } from "react";
import { useDrag, useDrop } from 'react-dnd';

import { ITEM_TYPES } from './constants';
import ContributionGraph from "./ContributionGraph";
import { DraggableContributionGraphContainer } from "./StyledComponents";
import ContributionGraphActions from "./ContributionGraphActions";

const DraggableContributionGraph = ({
    id,
    index,
    moveContributionGraph,
    label,
    startDate,
    endDate,
    initialValues,
    classForValue,
    tooltipDataAttrs,
    onClick,
    progress,
    disableDelete,
    addContributionGraph,
    deleteContributionGraph,
    updateContributionGraph
}) => {
    const ref = useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: ITEM_TYPES.CONTRIBUTION_GRAPH,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveContributionGraph(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type: ITEM_TYPES.CONTRIBUTION_GRAPH,
        item: () => {
            return { id, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <DraggableContributionGraphContainer ref={ref} $isdragging={isDragging} data-handler-id={handlerId}>
            <ContributionGraph
                id={id}
                label={label}
                startDate={startDate}
                endDate={endDate}
                initialValues={initialValues}
                classForValue={classForValue}
                tooltipDataAttrs={tooltipDataAttrs}
                onClick={onClick}
                progress={progress}
                updateContributionGraph={updateContributionGraph}
            />
            <ContributionGraphActions
                index={index}
                disableDelete={disableDelete}
                addContributionGraph={addContributionGraph}
                deleteContributionGraph={deleteContributionGraph}
            />
        </DraggableContributionGraphContainer>
    );
};

export default DraggableContributionGraph;
