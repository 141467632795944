import React, { useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { ContributionGraphActionsContainer } from "./StyledComponents";

const ContributionGraphActions = ({ index, disableDelete, addContributionGraph, deleteContributionGraph }) => {

    const [isHovered, setIsHovered] = useState(false);
    const [isDeleteHovered, setIsDeleteHovered] = useState(false);
    const [isAddHovered, setIsAddHovered] = useState(false);

    const handleDeleteContributionGraph = () => {
        deleteContributionGraph(index);
    };

    const handleAddContributionGraph = () => {
        addContributionGraph(index);
    };

    return (
        <ContributionGraphActionsContainer
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <DeleteIcon
                sx={{
                    visibility: isHovered ? 'visible' : 'hidden',
                    fontSize: '23px',
                    color: disableDelete ? '#FF6D4F' : isDeleteHovered ? '#FF6D4F' : '#EC401D',
                }}
                onMouseEnter={() => setIsDeleteHovered(true)}
                onMouseLeave={() => setIsDeleteHovered(false)}
                onClick={disableDelete ? null : handleDeleteContributionGraph}
            />
            <AddCircleIcon
                sx={{
                    visibility: isHovered ? 'visible' : 'hidden',
                    marginTop: '10px',
                    fontSize: '22px',
                    color: isAddHovered ? '#74B959' : '#33A406',
                }}
                onMouseEnter={() => setIsAddHovered(true)}
                onMouseLeave={() => setIsAddHovered(false)}
                onClick={handleAddContributionGraph}
            />
        </ContributionGraphActionsContainer>
    );
};

export default ContributionGraphActions;
