import styled, { css } from 'styled-components';

const smallMobileStylesForContainer = css`
    @media (max-width: 480px) {
        /* Stack the child elements vertically */
        flex-direction: column;
        /* Stack children vertically */
        justify-content: center;
        /* Center children vertically */
        align-items: center;
    }
`;

export const mobileStylesForButtons = css`
    @media (max-width: 768px) {
        font-size: 0.7rem;
        padding: 0.25rem 0.5rem;
        margin: 0.125rem;
    }
`;

export const smallMobileStylesForButtons = css`
    @media (max-width: 480px) {
        font-size: 0.7rem;
        padding: 0.125rem 0.25rem;
        margin: 0.125rem;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    /* Stack children vertically */
    justify-content: center;
    /* Center children vertically */
    align-items: center;
    /* Center children horizontally */
    // height: 100vh;
    /* Full viewport height */
    width: 100vw;
    /* Full viewport width */
    font-family: "Kanit", monospace;
    text-align: center;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #FFFFFF;
    color: ${props => props.style?.themeColor || '#37352F'};
`;

export const ResponsiveContainer = styled(Container)`
    ${smallMobileStylesForContainer}
`;

export const SeesawStopWatchButton = styled.button`
    padding: 10px 30px;
    color: ${props => props.style?.themeColor || '#3A3A3A'};
    background-color: #FFFFFF;
    border: 1px solid ${props => props.style?.themeColor || '#3A3A3A'};
    border-radius: 100px;
    font-size: 16px;
    margin: 0 5px;
    outline: none;
    cursor: pointer;

    &:hover {
        color: #FFFFFF;
        background-color: ${props => props.style?.themeColor || '#3A3A3A'};
        border-color: ${props => props.style?.themeColor || '#3A3A3A'}; 
    }
`;
