import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const withAuthentication = (ChildComponent) => {
    const AuthenticatedComponent = (props) => {
        const loggedInVisibilityUser = localStorage.getItem('loggedInVisibilityUser');

        useEffect(() => {
            // console.log('Checking if user is logged in...', loggedInVisibilityUser);
        }, [loggedInVisibilityUser]);

        return loggedInVisibilityUser ? <ChildComponent {...props} /> : <Navigate to="/signin" />;
    }

    return AuthenticatedComponent;
};

export default withAuthentication;
