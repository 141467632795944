import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import TimeTable from './TimeTable';
import { TimeTableContainer } from './StyledComponents';
import { fetchTimeTables as fetchTimeTable } from './databaseService';
import TimeTableMenu from './TimeTableMenu';
import withAuthentication from '../withAuthentication';
import { isActiveDayValid } from './constants';

const TimeTables = () => {
    const { activeDay } = useParams();
    const navigate = useNavigate();

    const [timeTableData, setTimeTableData] = useState(null);
    const [subs, setSubs] = useState([]);

    const loadActiveDay = () => {
        const savedActiveDay = localStorage.getItem('timeTableActiveDay');
        const lastSetDate = new Date(localStorage.getItem('lastSetDate'));
        const now = new Date();
        const dayOfWeek = now.getDay();

        let activeDay;
        if (savedActiveDay && dayOfWeek === lastSetDate.getDay()) {
            activeDay = savedActiveDay;
        } else {
            switch (dayOfWeek) {
                case 0:
                    activeDay = 'sunday';
                    break;
                case 1: case 2: case 3: case 4:
                    activeDay = 'wfo';
                    break;
                case 5:
                    activeDay = 'wfh';
                    break;
                case 6:
                    activeDay = 'saturday';
                    break;
                default:
                    activeDay = 'wfh';
                    break;
            }
        }

        return activeDay;
    };

    const saveActiveDay = (day) => {
        const now = new Date();
        localStorage.setItem('timeTableActiveDay', day);
        localStorage.setItem('lastSetDate', now.toISOString());
    };

    useEffect(() => {
        return () => {
            subs.forEach((sub) => sub.unsubscribe());
        }
    }, []);

    useEffect(() => {
        if (activeDay) {
            if (!isActiveDayValid(activeDay)) {
                navigate('/404');
            } else {
                (async () => {
                    const sub = await fetchTimeTable(activeDay, (timeTableDocument) => {
                        setTimeTableData({
                            id: timeTableDocument.id,
                            day: timeTableDocument.day,
                            timeTableItems: timeTableDocument.timeTableItems
                        });
                    });
                    setSubs([...subs, sub]);
                })();
            }
        } else {
            navigate(`/timetables/${loadActiveDay()}`);
        }
    }, [activeDay]);

    return (
        <TimeTableContainer >
            {timeTableData && <TimeTable timeTableData={timeTableData} />}
            <TimeTableMenu saveActiveDay={saveActiveDay} activeDay={activeDay} />
        </TimeTableContainer>
    );
};

export default withAuthentication(TimeTables);
