import { jwtDecode } from 'jwt-decode';

export const getLoggedInUser = () => {
    const loggedInVisibilityUser = localStorage.getItem('loggedInVisibilityUser');
    if (loggedInVisibilityUser) {
        try {
            return jwtDecode(loggedInVisibilityUser);
        } catch (error) {
            console.error('Error decoding logged in user token:', error);
        }
    }
};
