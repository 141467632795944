import styled from 'styled-components';
import { 
    SeesawStopWatchButton, 
    mobileStylesForButtons, 
    smallMobileStylesForButtons 
} from '../StyledComponents';

const SettingsButton = styled(SeesawStopWatchButton)`
    display: ${props => props.$show ? 'flex' : 'none'};
    flex-basis: 48%;
    position: absolute;
    right: 10px;
    bottom: 10px;
    // border: 1px solid black;
`;

export const ResponsiveSettingsButton = styled(SettingsButton)`
    ${mobileStylesForButtons}
    ${smallMobileStylesForButtons}
`;

export const SettingsModal = styled.div`
    width: 350px;
    font-family: "Arial";
    display: ${props => props.$show ? 'block' : 'none'};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    color: black;
    padding: 20px;
    border-radius: 20px;
    z-index: 9999;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* subtle shadow for depth */
    justify-content: space-between;
    margin-top: 10px;
    flex-basis: 48%;
`;

export const SettingsModalHeader = styled.h3`
    margin-bottom: 15px;
    font-family: "Verdana";
    font-size: 1.3rem;
`;

export const SettingsModalLabel = styled.label`
    display: block;
    margin-bottom: 10px;
    text-align: left;
`;

export const SettingsModalSelect = styled.select`
    margin-bottom: 10px;
    display: block;
    width: 100%; /* Make select fill container */
    padding: 8px; /* Add some padding */
    margin-bottom: 15px;
    border: 1px solid #ccc; /* Subtle border */
    border-radius: 5px; /* Rounded corners */
    background-color: white;
    box-sizing: border-box; /* Include padding and border in the element's width */
    cursor: pointer;
`;

export const SettingsModalButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

export const SettingsModalButton = styled(SeesawStopWatchButton)`
    /* A reset of styles, including removing the default dropdown arrow */
    appearance: none;
    /* Additional resets for further consistency */
    background-color: white;
    margin: 10px;
    width: 60%;
    font-family: inherit;
    line-height: inherit;
    flex-basis: 48%;
`;
