import React from 'react';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { upsertTimeTableItem } from './databaseService';
import { AVAILABLE_ICONS } from './constants';

dayjs.extend(customParseFormat);

const TimeTableItemTimeSlot = ({
    invalid,
    timeTableId,
    id,
    startTime,
    endTime,
    icon,
    title,
    description,
    showTime
}) => {
    const handleOnChange = async (e) => {
        const iconIndex = AVAILABLE_ICONS.findIndex(({ id }) => id === icon);
        let newStartTime = '', newEndTime = '';
        if (e) {
            newStartTime = dayjs(e[0]).format("h:mm a");
            newEndTime = dayjs(e[1]).format("h:mm a");
        }
        await upsertTimeTableItem(
            timeTableId,
            id,
            newStartTime,
            newEndTime,
            AVAILABLE_ICONS[iconIndex].id,
            title,
            description,
            showTime);
    };

    return (
        <TimePicker.RangePicker
            use12Hours
            order={false}
            format="h:mm a"
            variant={invalid ? "outlined" : "borderless"}
            status={invalid ? "error" : ""}
            size="small"
            value={
                (startTime.length !== 0 && endTime.length !== 0) ?
                    [dayjs(startTime, "h:mm a"), dayjs(endTime, "h:mm a")] : null
            }
            style={{
                width: '50%',
            }}
            onChange={handleOnChange}
        />
    );
};

export default TimeTableItemTimeSlot;
