import React, { useRef } from "react";
import { useDrag, useDrop } from 'react-dnd';

import { ITEM_TYPES } from "./constants";
import TimeTableItem from "./TimeTableItem";

const DraggableTimeTableItem = ({
    index,
    invalid,
    id,
    timeTableId,
    startTime,
    endTime,
    icon,
    title,
    description,
    showTime = true,
    moveTimeTableItem
}) => {
    const ref = useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: ITEM_TYPES.TIME_TABLE_ITEM,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveTimeTableItem(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type: ITEM_TYPES.TIME_TABLE_ITEM,
        item: () => {
            return { id, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;

    drag(drop(ref));

    return (
        <TimeTableItem
            ref={ref}
            data-handler-id={handlerId}
            index={index}
            invalid={invalid}
            id={id}
            timeTableId={timeTableId}
            startTime={startTime}
            endTime={endTime}
            icon={icon}
            title={title}
            description={description}
            showTime={showTime}
        />
    );
};

export default DraggableTimeTableItem;
