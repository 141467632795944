import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';

const TimeTableMeter = ({ startTime, endTime, setIsHighlighted }) => {

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
            // scrollToHighlightedDot();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const getTimestamps = (startTime, endTime) => {
        const parseTime = (time) => {
            const [hourMinute, amPm] = time.split(' ');
            const [hour, minute] = hourMinute.split(':').map(Number);
            return {
                hour: (hour === 12 ? 0 : hour) + (amPm.toLowerCase() === 'pm' ? 12 : 0),
                minute: minute
            };
        };

        const start = parseTime(startTime);
        const end = parseTime(endTime);

        let currentHour = currentTime.getHours();
        const currentMinute = currentTime.getMinutes();

        if (end.hour < start.hour || (end.hour === start.hour && end.minute < start.minute)) {
            end.hour += 24;
            if (currentHour < 12) {
                currentHour += 24;
            }
        }

        const startTimestamp = start.hour * 60 + start.minute;
        const endTimestamp = end.hour * 60 + end.minute;
        const currentTimestamp = currentHour * 60 + currentMinute;

        return {
            startTimestamp,
            endTimestamp,
            currentTimestamp
        };
    }

    const {
        startTimestamp,
        endTimestamp,
        currentTimestamp
    } = getTimestamps(startTime, endTime);

    const isCurrentTimeInRange = (startTimestamp, endTimestamp, currentTimestamp) => {
        return currentTimestamp >= startTimestamp && currentTimestamp < endTimestamp;
    }

    const convertMinutesToHoursAndMinutes = (minutes) => {
        var hours = Math.floor(minutes / 60);
        var remainingMinutes = minutes % 60;
        return [hours, remainingMinutes];
    }

    const isHighlighted = isCurrentTimeInRange(startTimestamp, endTimestamp, currentTimestamp);
    let timeRemaining = '';
    if (isHighlighted) {
        const [hours, minutes] = convertMinutesToHoursAndMinutes(endTimestamp - currentTimestamp);
        timeRemaining = hours === 0 ?
            `Time left: ${minutes} minutes` : minutes === 0 ?
                `Time left: ${hours} hours` : `Time left: ${hours} hours ${minutes} minutes`;
    } else {
        const [hours, minutes] = convertMinutesToHoursAndMinutes(endTimestamp - startTimestamp);
        timeRemaining = hours === 0 ?
            `Total time: ${minutes} minutes` : minutes === 0 ?
                `Total time: ${hours} hours` : `Total time: ${hours} hours ${minutes} minutes`;
    }

    useEffect(() => {
        setIsHighlighted(isHighlighted);
    }, [isHighlighted, setIsHighlighted]);

    return (
        <Typography color={isHighlighted ? '#EA5221' : 'grey'}>
            {timeRemaining}
        </Typography>
    );
};

export default TimeTableMeter;
