import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import Settings from './settings/Settings';
import { ResponsiveContainer } from './StyledComponents';
import Timer from './timer/Timer';
import { selectStyle } from './seesawStopWatchSlice';
import ActivityLog from './ActivityLog';
import withAuthentication from '../withAuthentication';

const SeesawStopWatch = () => {
  const style = useSelector(selectStyle);
  return (
    <ResponsiveContainer style={style}>
      <Timer />
      <ActivityLog />
      <Settings/>
    </ResponsiveContainer>
  );
};

export default withAuthentication(SeesawStopWatch);
